import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvService} from '../utils/env.service';
import {constants} from '../../../config/app.constants';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    private contentHeader: any = {};

    constructor(private http: HttpClient,
                private env: EnvService) {
    }

    blogEventsHeader() {
        const urlHeader = this.env.apiGatewayFront + constants.config.getContentB2S;
        this.contentHeader = this.http.get(urlHeader);
        return this.contentHeader;
    }

}
