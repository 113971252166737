import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {ContactComponent} from './contact/contact.component';
import {SupplierRegistrationComponent} from './supplier-registration/supplier-registration.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {TermsComponent} from './terms/terms.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {BrowserNotSupportedComponent} from './browser-not-supported/browser-not-supported.component';
import {OfflineExpectationComponent} from './offline-expectation/offline-expectation.component';
import {ModulesComponent} from './modules/modules.component';
import {SharedModule} from './shared/shared.module';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './gards/auth.gard';
import {ToastrModule} from 'ngx-toastr';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InternalErrorComponent} from './modules/internal-error/internal-error.component';

import {PasswordComponent} from './password/password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {NgxPaginationModule} from 'ngx-pagination';

import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig} from 'ngx-ui-loader';
import {OriginInterceptor} from './shared/interceptors/origin.interceptor';
import {KeepHtmlPipe} from './pipes/keep-html.pipe';
import {EnvServiceProvider} from './services/utils/env.service.provider';
import {constants} from '../config/app.constants';
import { SearchCompanyComponent } from './search-company/search-company.component';
import { SupplierRegistrationCompanyComponent } from './supplier-registration-company/supplier-registration-company.component';

import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#00ACC1',
    bgsOpacity: 0.5,
    bgsPosition: 'bottom-right',
    bgsSize: 60,
    bgsType: 'ball-spin-clockwise',
    blur: 8,
    fgsColor: 'rgba(255,255,255,0.77)',
    fgsPosition: 'center-center',
    fgsSize: 70,
    fgsType: 'ball-spin-clockwise',
    gap: 48,
    logoPosition: 'center-center',
    logoSize: 120,
    logoUrl: '',
    masterLoaderId: 'master',
    overlayBorderRadius: '0',
    overlayColor: 'rgba(40, 40, 40, 0.8)',
    pbColor: '#0a4b96',
    pbDirection: 'ltr',
    pbThickness: 4,
    hasProgressBar: true,
    text: 'Procesando la solicitud...',
    textColor: '#FFFFFF',
    textPosition: 'bottom-center',
    threshold: 500
};

const url = constants.config.apiGatewayFront;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ContactComponent,
        SupplierRegistrationComponent,
        TermsComponent,
        PrivacyComponent,
        BrowserNotSupportedComponent,
        OfflineExpectationComponent,
        ModulesComponent,
        NotFoundComponent,
        InternalErrorComponent,
        NotFoundComponent,
        PasswordComponent,
        ContactUsComponent,
        KeepHtmlPipe,
        SearchCompanyComponent,
        SupplierRegistrationCompanyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        ScrollingModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule.forRoot({
            showForeground: true,
            exclude: [
                `${url}${constants.config.adverstismentApi}`,
                `${url}${constants.config.getContentB2S}`,
                `${url}${constants.config.pageOffline}`,
                `${url}${constants.config.getContentCmsInfo}`,
                `${url}${constants.config.adverstismentCmsSpaces}`,
                `${url}${constants.config.getContentB2S}`]
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        ToastrModule.forRoot({
            timeOut: 10000,
            extendedTimeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true,
        }),
        CommonModule,
        BrowserAnimationsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgxPaginationModule,
    ],
    exports: [
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgxPaginationModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: OriginInterceptor, multi: true},
        AuthGuard,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: constants.config.siteKeyCaptcha,
            } as RecaptchaSettings,
        },
        EnvServiceProvider,
        BnNgIdleService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
