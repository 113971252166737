import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Router, RouterStateSnapshot } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  blogEvent: any;
  logo: string;
  jsonUser: any;
  nameUser: string;
  nameSupplier: string;
  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;

  // tslint:disable-next-line:variable-name
  constructor(private _authService: AuthService, private _router: Router) { }

  ngOnInit() {
    this.logo = localStorage.getItem('logo');
    this.jsonUser = JSON.parse(localStorage.getItem('userData'));
    console.log(this.jsonUser);
    this.nameUser = this.jsonUser.contactFirstName;
    this.nameSupplier = this.jsonUser.thirdName;
  }

  logout() {
    this._authService.logoutService();
    localStorage.removeItem('authenticate');
    localStorage.removeItem('userData');
    localStorage.removeItem('descarga_certificado');
    this._router.navigate(['/login']);
  }

}
