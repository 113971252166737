const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

export const constants = {
    config: {
        apiGatewayFront: browserWindowEnv.apiGatewayFront,
        siteKeyCaptcha: browserWindowEnv.siteKeyCaptcha,
        adverstismentApi: 'template/cms-spaces?&ad=0',
        adverstismentCmsSpaces: 'template/cms-spaces',
        pageOffline: 'param/get-app-params',
        apdateUser: 'user/update-user',
        getTypeDocumentsUser: 'user/get-tipo-documentos',
        getLocationByText: 'location/get-location-by-search-text',
        supplierGetEconomicActivities: 'supplier-registration/get-economic-activities',
        supplierGetEconomicSubActivities: 'supplier-registration/get-economic-subactivities',
        supplierGetTaxRegimes: 'supplier-registration/get-tax-regimes',
        supplierGetCities: 'supplier-registration/get-cities',
        supplierValidateContactIdentification: 'supplier-registration/validate-contact-identification',
        supplierValidateNitSupplierRegister: 'supplier-registration/validate-nit-supplier-register',
        supplierValidateEmailContactSupplier: 'supplier-registration/validate-email-contact-supplier',
        supplierRegister: 'supplier-registration/register-supplier',
        getAffairs: 'contact/get-affairs',
        getCompanieInfo: 'contact/get-companie-info',
        getRelatedTo: 'contact/get-related-to',
        getContactThrough: 'contact/get-contact-through',
        sendMaildContact: 'contact/enviar-correo-contactenos',
        getBlogList: 'blog/blog-list?limit=5&page=1',
        getPostBlog: 'blog/get-post?slug',
        getBlogCategory: 'blog/blog-list?category',
        limitBlog: '&limit=5&page=1',
        getTagBlog: 'blog/blog-list?limit=5&page=1&tag',
        getBlogPanel: 'blog/blog-list-panel',
        getSearchBlog: 'blog/blog-list?limit=5&page=1&searchword',
        getContentCms: 'template/cms-info?ubicacion',
        getContentCmsInfo: 'template/cms-info',
        getDataAccountStatements: 'consult-b2s/consulta-cuentas-x-pagar',
        getPaymentsMade: 'consult-b2s/consulta-facturas-canceladas-b2s',
        updatePassword: 'user/update-password',
        userLogin: 'login/user-login',
        getContentB2S: 'param/get-content-b2s',
        getUserInfo: 'user/user-info',
        recoverPassword: 'login/recover-password',
        infoPage: 'template/info-page',
        rteFuentePdf: 'consult-b2s/rtefuente-pdf',
        rteIcaPdf: 'consult-b2s/rteica-pdf',
        rteRsbPdf: 'consult-b2s/rtersb-pdf',
        rteFuenteAiPdf: 'consult-b2s/rterentaai-pdf',
        rteIvaAiPdf: 'consult-b2s/rteivaai-pdf',
        rteIcaAiPdf: 'consult-b2s/rteicaai-pdf',
        getTercerosCertificados: 'consult-b2s/terceros-certificados',
        rteIvaPdf: 'consult-b2s/rteiva-pdf',
        createPayment: 'pronto-pago/create-payment',
        validatePayment: 'pronto-pago/validate-payment',
        certificateYear: 'consult-b2s/certificado-fecha',
        getValidateCertifiedRtefuenteGeneration: 'consult-b2s/validar-generacion-rte-fuente-pdf',
        getValidateCertifiedRteicaGeneration: 'consult-b2s/validar-generacion-rte-ica-pdf',
        getValidateCertifiedRteIvaGeneration: 'consult-b2s/validar-generacion-rte-iva-pdf',
        getValidateCertifiedRtersbGeneration: 'consult-b2s/validar-generacion-rte-rsb-pdf',
        getValidateCertifiedRtefuenteAiGeneration: 'consult-b2s/validar-generacion-rte-fuente-ai-pdf',
        getValidateCertifiedRteicaAiGeneration: 'consult-b2s/validar-generacion-rte-ica-ai-pdf',
        getValidateCertifiedRteIvaAiGeneration: 'consult-b2s/validar-generacion-rte-iva-ai-pdf',
        getPdfEgresos: 'consult-b2s/comprobante-egreso-pdf',
        getCompanySuplier: 'supplier-registration/get-company-info-erp',
        getCompanySuplierRegister: 'supplier-registration/get-company',
        myUsersList : 'user-admin/users-related-to-admin',
        createUser: 'user-admin/create-update-user-by-admin',
        userData: 'user-admin/preload-user-created-by-admin',
        emailValidator: 'login/validate-email',
        sendInvitation: 'user-admin/send-invitation',
        sendInvitationUser: 'user-admin/send-invitation-user',
        enableOrDisableUser: 'user-admin/enable-user-admin',
        sendDataCertificates: 'supplier-registration/register-supplier-document',
        identificationValidator: 'user-admin/user-by-identification',
        getPurchaseOrder: 'consult-b2s/consulta-orden-compra',
        getCertificadosCamaraComercio: 'consult-b2s/listado-certificado-empresa',
        recordatorioVencimientoDocumentos: 'consult-b2s/recordatorio-vencimiento-documentos',
        getCidudadesAplicacionCertificados: 'consult-b2s/consulta-ciudades-certificados',
        getDetailPurchaseOrder: 'consult-b2s/consulta-orden-compra-detalle',
        getClaseRetencionCertificados: 'consult-b2s/consulta-clase-retencion-certificados',

    }
};
