import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {EnvService} from '../utils/env.service';
import {constants} from '../../../config/app.constants';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient,
                private env: EnvService) {}

    authenticate(username: string, password: string) {

        const url = this.env.apiGatewayFront + constants.config.userLogin;

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/form-data');
        let body: FormData = new FormData();

        body.append('username', username);
        body.append('password', password);

        return this.http.post(url, body, { headers });
    }

    isAutenticate() {
        let localData = JSON.parse(localStorage.getItem('authenticate'));
        try {
            if (localData ) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    }

    logoutService() {
        localStorage.setItem('authenticate', 'false');
      }
}
