import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login/login.service';
import swal from "sweetalert2";

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  registerForm: FormGroup;
  contentCms: any;
  logo: string;
  public data: any;
  public dataForm = {
    inputEmail: '',
    inputPassword: ''
  };
  public contentB2s = {
    contact: 0,
    provider: 0
  } ;

  constructor(private _authService: AuthService,
              private _router: Router,
              private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private _loginService: LoginService) {

    if (this._authService.isAutenticate()) {
      this._router.navigate(['/account-statements', 0]);
    }
    this.getCmsContent();

    }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    this.logo = localStorage.getItem('logo');
    const interval = setInterval(() => {
      this.getContentB2s();
      clearInterval(interval);
    }, 1250);
  }

  authentication(form: NgForm) {
    let userName = form.value.inputEmail;
    let password = form.value.inputPassword;
    let message = '';

    this.registerForm = this.formBuilder.group({
      email: [userName, [Validators.required, Validators.pattern('^([a-zA-Z0-9_.+-])+\\@(([a-zA-Z0-9-])+\\.)+([a-zA-Z0-9]{2,4})+$')]],
      password: [password, [Validators.required]]
    });

    if (this.registerForm.controls.email.errors) {
      if (this.registerForm.controls.email.errors.required) {
        message = message + '<br>Ingrese el correo electrónico';
      }
      if (this.registerForm.controls.email.errors.pattern) {
        message = message + '<br>Ingrese un correo válido.';
      }
    }
    if (this.registerForm.controls.password.errors) {
      if (this.registerForm.controls.password.errors.required) {
        message = message + '<br>Ingrese la contraseña.';
      }
    }

    if (this.registerForm.invalid) {
      this.toastr.error(message, 'faltan algunos datos por diligenciar', {enableHtml: true});
      /*swal.fire({
        title: '<p>faltan algunos datos por diligenciar</p>',
        html: message,
        type: 'error',
      });*/
      return;
    }

    this._authService.authenticate(userName, password)
      .subscribe((authResponse: any) => {
        if (authResponse.data) {
          if (authResponse.data.isLogged) {
            if (authResponse.data.isB2S) {
              localStorage.setItem('authenticate', 'true');
              localStorage.setItem('userData', JSON.stringify(authResponse.data));
              this._router.navigate(['/account-statements', 0]);
            } else {
              localStorage.setItem('authenticate', 'false');
              this._router.navigate(['/login']);
              this.showError('Lo sentimos! Este usuario no cuenta con permisos para ingresar al aplicativo');
            }
          }
        } else {
          localStorage.setItem('authenticate', 'false');
          this.showError(authResponse.message);
        }
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        }
      });
  }

    getCmsContent() {
        const ubucation = 'REGISTRO_B2S';
        this._loginService.contentCms(ubucation)
            .subscribe((loginResponse: any) => {
                return this.contentCms = loginResponse;
            });
    }

  getContentB2s() {
    const provider = localStorage.getItem('provider');
    const contact = localStorage.getItem('contact');

    this.contentB2s.contact = parseFloat(contact);
    this.contentB2s.provider = parseFloat(provider);
  }

  showError(message) {
    this.toastr.error(message, '', { enableHtml: true });
  }

}

