import { Component, OnInit } from '@angular/core';
import { AdverstismentService } from '../shared/services/advertisments/adverstisment.service';
import { SearchCompanyService } from 'src/app/services/search-company/search-company.service';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierRegistrationService } from '../services/supplier-registration/supplier-registration.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';


@Component({
  selector: 'app-supplier-registration-company',
  templateUrl: './supplier-registration-company.component.html',
  styleUrls: ['./supplier-registration-company.component.scss']
})
export class SupplierRegistrationCompanyComponent implements OnInit {

  cmsData: any;
  page = 'PAG-47';
  b2sContent: any;
  cmsMessage: any;
  termino: string;
  registerForm: FormGroup;
  public dataForm = {
    nitCompany: ''
  };
  public companyInfo;
  public messageError;
  public validateModel: any;
  public listCities = [];
  public captchaValue: any;
  public taxRegimes: any;

  public identificationsTypes: [];
  public identfy: any =[];

  public supplierModel = {
    businessName: '',
    shortName: '',
    documentID: '',
    documentIdVerify: '',
    economicActivity: '',
    economicSubactivity: '',
    webSite: '',
    pyme: '',
    messageBusiness: '',
    taxRegime: '',
    requestType: '2',
    contactName: '',
    contactLastname: '',
    contactIdentificationType: '',
    contactIdentification: '',
    contactCharge: '',
    contactArea: '',
    contactIndicativeTelephone: '',
    contactTelephone: '',
    contactEmail: '',
    contactCity: null,
    contactAddress: '',
    captchaValue: null,
    checkAcepto: null,
    checkAutorizo: null
  };

  cityTextSearch: string;
  selectedCity = false;
  supplierCity: any;
  public userLogged: string;


  // tslint:disable-next-line:variable-name
  constructor(private _adService: AdverstismentService,
    private _searchCompanyService: SearchCompanyService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _principalService: SupplierRegistrationService,
    private _toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
    this.initModelSupplier();
    this.getSuplierCompanyInfo();
    this.initAdverstisments();
    this.getContentB2s();
    this.getContentCms();
    this.initFieldsForm();
  }

  initModelSupplier() {
    this.supplierModel = {
      businessName: '',
      shortName: '',
      documentID: '',
      documentIdVerify: '',
      economicActivity: '',
      economicSubactivity: '',
      webSite: '',
      pyme: '',
      messageBusiness: '',
      taxRegime: '',
      requestType: '2',
      contactName: '',
      contactLastname: '',
      contactIdentificationType: '',
      contactIdentification: '',
      contactCharge: '',
      contactArea: '',
      contactIndicativeTelephone: '',
      contactTelephone: '',
      contactEmail: '',
      contactCity: null,
      contactAddress: '',
      captchaValue: null,
      checkAcepto: null,
      checkAutorizo: null
    };
    this.validateModel = {
      businessName: true,
      shortName: true,
      documentID: true,
      documentIdVerify: true,
      economicActivity: true,
      economicSubactivity: true,
      webSite: true,
      pyme: true,
      contactName: true,
      contactLastname: true,
      contactIdentificationType: true,
      contactIdentification: true,
      contactIndicativeTelephone: true,
      contactTelephone: true,
      contactEmail: true,
      contactCity: true,
      contactAddress: true,
      taxRegime: true
    };
    this.cityTextSearch = '';
    this.selectedCity = false;
    this.supplierCity = null;
  }

  initFieldsForm() {
    this._principalService.getIdentificationsTypes()
      .subscribe((data: any) => {
        if (this.companyInfo.length > 0) {
         this.identfy =[];

         data.forEach((element: any) => {
            if (element[`codigo`] !== 'NIT') {

              this.identfy.push(element);

            }

         });

        } else {

          this.identificationsTypes = data;
          console.log('menor: ', this.identificationsTypes, 'this.companyInfo : ', this.companyInfo);

        }

        
      });
  }

  getSuplierCompanyInfo() {
    this.route.params
      .subscribe(parametros => {
        this._searchCompanyService.postGetDataCompany(parametros.nit)
          .subscribe((companyResponse: any) => {
            this.termino = parametros.termino;
            this.companyInfo = companyResponse.data;
            this.setDataCompany(this.companyInfo);
            console.log(this.companyInfo);
          });
      });
  }

  initAdverstisments() {
    this._adService.getAdverstisments(this.page).subscribe((data: any) => {
      this.cmsData = data;
    });
  }

  getContentB2s() {
    this._searchCompanyService.contentParamsB2s()
      .subscribe((contentReponse: any) => {
        return this.b2sContent = contentReponse;
      });
  }

  getContentCms() {
    this._searchCompanyService.contentCmsProvider()
      .subscribe((contentCmsResponse: any) => {
        return this.cmsMessage = contentCmsResponse;
      });
  }

  validateContactIdentificationRegisterSupplier(idDocument, document) {
    this._principalService.validateContactIdentificationRegisterSupplier(idDocument, document)
      .subscribe((data: any) => {
        if (data.error) {
          this.showToast('error', data.message, data.title, {});
          this.supplierModel.contactIdentification = '';
          this.supplierModel.contactIdentificationType = '';
        }
      });
  }

  validateEmailContactSupplier(emailContact) {
    if (emailContact) {
      this._principalService.validateEmailContactSupplier(emailContact)
        .subscribe((data: any) => {
          if (data.error) {
            this.showToast('error', data.message, data.title, {});
            this.supplierModel.contactEmail = '';
          }
        });
    }
  }

  showToast(type, message, title, options) {
    if (type === 'error') {
      this._toastr.error(message, title, options);
    } else if (type === 'warning') {
      this._toastr.warning(message, title, options);
    } else if (type === 'success') {
      this._toastr.success(message, title, options);
    } else if (type === 'info') {
      this._toastr.info(message, title, options);
    }
  }

  getCitiesBySearchText(textSearch) {
    this.selectedCity = false;
    this.supplierCity = {};
    this.supplierModel.contactCity = null;
    if (textSearch.length >= 3) {
      this._principalService.getCities(textSearch)
        .subscribe((data: any) => {
          this.listCities = data;
        });
    }
  }

  setCityByAutocompleteField(city) {
    this.selectedCity = true;
    this.cityTextSearch = city.name;
    this.supplierCity = city;
    this.supplierModel.contactCity = city;
  }

  setDataCompany(dataComapny: any) {
    let company;
    company = dataComapny[0];
    this.supplierModel.businessName = company.nombre;
    this.supplierModel.shortName = company.sigla;
    this.supplierModel.documentID = company.documento_numero;
    this.supplierModel.documentIdVerify = company.documento_dv;
    this.supplierModel.economicActivity = company.id_actividad_economica;
    this.supplierModel.taxRegime = company.regimen_tributario_id;
    this.supplierModel.webSite = company.web_site;
    this.supplierModel.pyme = company.pyme;
    this.supplierModel.messageBusiness = company.observacion_tercero;

  }

  validateForm() {
    let flag = true;
    let messageValidate = 'Para continuar con este proceso debes diligenciar los siguientes datos:';

    if (this.supplierModel.contactName.length <= 0 ||
      this.supplierModel.contactLastname.length <= 0 ||
      (this.supplierModel.contactIdentificationType.length <= 0) ||
      this.supplierModel.contactIdentification.length <= 0 ||
      this.supplierModel.contactEmail.length <= 0 ||
      this.supplierModel.contactIndicativeTelephone.length <= 0 ||
      this.supplierModel.contactTelephone.length <= 0 ||
      this.supplierModel.contactTelephone.length < 10 ||
      this.supplierModel.contactAddress.length <= 0 ||
      (!this.selectedCity && !this.supplierModel.contactCity) ||
      (!this.supplierModel.captchaValue || !this.supplierModel.checkAcepto || !this.supplierModel.checkAutorizo)
    ) {

      if (this.supplierModel.contactName.length <= 0) {
        messageValidate += '<br>Nombre(s)';
        this.validateModel.contactName = false;
      }
      if (this.supplierModel.contactLastname.length <= 0) {
        messageValidate += '<br>Apellido(s)';
        this.validateModel.contactLastname = false;
      }
      if (this.supplierModel.contactIdentificationType.length <= 0) {
        messageValidate += '<br>Tipo Identificación';
        this.validateModel.contactIdentificationType = false;
      }
      if (this.supplierModel.contactIdentification.length <= 0) {
        messageValidate += '<br>Numero de Identificación';
        this.validateModel.contactIdentification = false;
      }
      if (this.supplierModel.contactIndicativeTelephone.length <= 0) {
        messageValidate += '<br>Indicativo';
        this.validateModel.contactIndicativeTelephone = false;
      }
      if (this.supplierModel.contactIndicativeTelephone.length > 4) {
        messageValidate += '<br>El Indicativo no puede ser mayor a 4 digitos';
        this.validateModel.contactIndicativeTelephone = false;
      }
      if (this.supplierModel.contactTelephone.length <= 0) {
        messageValidate += '<br>Teléfono Móvil';
        this.validateModel.contactTelephone = false;
      }
      if (this.supplierModel.contactTelephone.length < 10) {
        messageValidate += '<br>El Teléfono Móvil  debe tener 10 digitos';
        this.validateModel.contactTelephone = false;
      }
      if (this.supplierModel.contactTelephone.length > 9 && this.supplierModel.contactTelephone.length !== 10) {
        messageValidate += '<br>El Teléfono Móvil  debe tener 10 digitos';
        this.validateModel.contactTelephone = false;
      }
      if (this.supplierModel.contactEmail.length <= 0) {
        messageValidate += '<br>Email';
        this.validateModel.contactEmail = false;
      }
      if (this.supplierModel.contactAddress.length <= 0) {
        messageValidate += '<br>Dirección';
        this.validateModel.contactAddress = false;
      }

      if (!this.selectedCity && !this.supplierModel.contactCity) {
        messageValidate += '<br>Debe buscar y seleccionar una ciudad';
        this.validateModel.contactCity = false;
      }

      if (!this.supplierModel.checkAcepto) {
        messageValidate += ' <br>Aceptar términos y condiciones';
        flag = false;
      }
      if (!this.supplierModel.checkAutorizo) {
        messageValidate += ' <br>Autorizar tratamiendo de datos';
        flag = false;
      }

      if (!this.supplierModel.captchaValue) {
        messageValidate += ' <br>Resolver el CAPTCHA';
        flag = false;
      }

      flag = false;
      swal.fire({
        title: '<p>Faltan algunos datos por diligenciar</p>',
        html: messageValidate,
      });
    } else {
      if (!this.validateFormatEmail(this.supplierModel.contactEmail)) {
        flag = false;
      }
    }


    return flag;
  }

  onSubmit() {
    if (this.validateForm()) {
      this._principalService.postRegisterContactSupplier(this.supplierModel)
        .subscribe((data: any) => {
          if (data.error) {
            this.showToast('error', data.errorMessage, 'Solicitud Proveedores', {});
          } else {
            this.showToast('success', data.errorMessage, 'Solicitud exitosa', {});
            this.initModelSupplier();
            this.router.navigate(['/search-company']);
          }
        });
    }
  }

  validateFormatEmail(email) {
    let validate = true;
    // tslint:disable-next-line:max-line-length
    const regularExpression = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    validate = regularExpression.test(email);

    if (!validate) {
      swal.fire({
        title: '<p>Faltan algunos datos por diligenciar</p>',
        html: '<br>Debe ingresar un correo con un formato valido.',
      });
    }
    return validate;
  }


}
