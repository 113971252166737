import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {EnvService} from '../../../services/utils/env.service';
import {constants} from '../../../../config/app.constants';

@Injectable({
    providedIn: 'root',
})
export class AdverstismentService {

    constructor(private http: HttpClient,
                private env: EnvService) {
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    getAdverstisments(page: string): Observable<any> {

        const url = this.env.apiGatewayFront + `${constants.config.adverstismentApi}&pageName=${page}`;

        return this.http.get<any>(url).pipe(
            retry(1)
        );
    }

    getCities(data: any) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/form-data');
        const body: FormData = new FormData();
        const url = this.env.apiGatewayFront + `${constants.config.getLocationByText}`;
        body.append('citiesERP', data.citiesERP);
        body.append('searchText', data.searchText);
        return this.http.post<any>(url, body, {headers});
    }

  getInfoPage(data: any) {
    return this.http.post<any>(this.env.apiGatewayFront + constants.config.infoPage, data);
  }

    getDownloadCertificados() {

        return this.http.get<any>(this.env.apiGatewayFront + constants.config.getCertificadosCamaraComercio);
        // getCertificadosCamaraComercio: '',
    }


}
