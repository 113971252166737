import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvService} from '../utils/env.service';
import {constants} from '../../../config/app.constants';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private contenidoCms: any = {};

    constructor(private http: HttpClient,
                private env: EnvService) {
    }

    getService(form): Observable<any> {
        const body = new FormData();
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/form-data');
        for (let propiedad in form.value) {
            let valuePropety = form.value[propiedad];
            if (valuePropety == null){
                valuePropety = '';
            }
            body.append(propiedad, form.value[propiedad]);
        }
        const url = this.env.apiGatewayFront + constants.config.sendMaildContact;
        return this.http.post(url, body, {headers});
    }

    contentCms(ubication: string) {
        const urlCms = this.env.apiGatewayFront + constants.config.getContentCms + `=${ubication}`;
        this.contenidoCms = this.http.get(urlCms);
        return this.contenidoCms;
    }
}
