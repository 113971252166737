import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvService} from '../utils/env.service';
import {constants} from '../../../config/app.constants';

@Injectable({
    providedIn: 'root'
})
export class AffairsContactService {
    constructor(
        private http: HttpClient,
        private env: EnvService) {
    }

    getService(): Observable<any> {
        const url = this.env.apiGatewayFront + constants.config.getAffairs;
        return this.http.get(url);
    }
}
