import { Component, OnInit } from '@angular/core';
import {AdverstismentService} from '../../shared/services/advertisments/adverstisment.service';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class InternalErrorComponent implements OnInit {

  constructor(private _loginService: LoginService,
              private _router: Router) { 
    this.statusServer();
  }

  ngOnInit() {
  }

  statusServer() {
    this._loginService.statusServer()
      .subscribe((authResponse: any) => {
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        }else{
          this._router.navigate(['/login']);
        }
      });
  }
}
